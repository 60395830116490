import { Spin } from 'antd';

import styles from './Spinner.module.css';

type SpinnerSize = 'small' | 'default' | 'large';

type SpinnerProps = {
  size?: SpinnerSize;
  height?: string;
};

export const Spinner = ({ size, height }: SpinnerProps): JSX.Element => {
  return (
    <div className={styles.spinnerWrapper} style={{ height }}>
      <Spin size={size} />
    </div>
  );
};
