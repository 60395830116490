import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';

import { SearchBar } from '../../../components/SearchBar';
import { AddOrEditModal } from './AddOrEditModal';

type HeaderProps = {
  searchValue: string;
  onSearch: (value: string) => void;
  onAdd: () => void;
};

export const Header = ({ searchValue, onSearch, onAdd }: HeaderProps): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = () => setModalVisible(true);
  const handleModalClose = () => setModalVisible(false);

  return (
    <>
      <h1>Roles</h1>

      <Row gutter={32}>
        <Col span={6}>
          <SearchBar value={searchValue} onChange={onSearch} />
        </Col>

        <Col>
          <Button type="primary" onClick={handleModalOpen}>
            <PlusOutlined />
            Add Roles
          </Button>
        </Col>
      </Row>

      <AddOrEditModal
        modalVisible={modalVisible}
        onCancel={handleModalClose}
        onSuccess={() => {
          handleModalClose();
          onAdd();
        }}
      />
    </>
  );
};
