import { ComponentType } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useMount } from 'react-use';

import { authState } from '../services/auth-state';
import { handleRedirectLogin } from '../utils/login';
import { getQueryString } from '../utils/query';

export const AuthenticatedRoute = ({
  component: Component,
  ...rest
}: RouteProps & { component: ComponentType }): JSX.Element => (
  <Route
    {...rest}
    render={(props) => {
      return authState.isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to={`/login?${getQueryString({ next: props.location.pathname })}`} />
      );
    }}
  />
);

export const UnauthenticatedRoute = ({
  component: Component,
  ...rest
}: RouteProps & { component: ComponentType }): JSX.Element => {
  const params = new URLSearchParams(window.location.search);
  const serviceRedirectUrl = params.get('service_redirect_url') ?? undefined;
  const clientId = params.get('client_id') ?? undefined;

  useMount(() => {
    if (serviceRedirectUrl && authState.isLoggedIn()) {
      handleRedirectLogin(serviceRedirectUrl, clientId);
    }
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        return !authState.isLoggedIn() ? <Component {...props} /> : <Redirect to="/" />;
      }}
    />
  );
};
