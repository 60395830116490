import { UploadOutlined } from '@ant-design/icons';
import { Spin, Upload, Button } from 'antd';
import React, { useMemo } from 'react';

type Props = {
  onChange?: (file?: File) => void;
  value?: string | File[] | null;
  showPreview?: boolean;
  uploading?: boolean;
  buttonText?: string;
};

export const FileUpload = ({ onChange, value, showPreview, uploading, buttonText }: Props): JSX.Element => {
  const fileList = useMemo(() => {
    if (typeof value === 'string') {
      return [{ url: value, thumbUrl: value, name: 'avatar', uid: 'avatar' }];
    } else {
      if (value instanceof File) {
        return [
          {
            ...value,
            thumbUrl: URL.createObjectURL(value),
            name: value.name,
            uid: 'avatar'
          }
        ];
      } else {
        return [];
      }
    }
  }, [value]);

  const props = {
    fileList: fileList,
    name: 'file',
    multiple: false,
    showUploadList: showPreview === undefined ? true : showPreview,
    beforeUpload: (file: File) => {
      if (onChange) {
        onChange(file);
      }

      return false;
    },
    onRemove: () => {
      if (onChange) {
        onChange();
      }
    }
  };

  return (
    <Upload {...props}>
      <Spin spinning={!!uploading}>
        <Button icon={<UploadOutlined />}>{buttonText ?? 'Upload'}</Button>
      </Spin>
    </Upload>
  );
};
