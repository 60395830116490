import { Pagination, Table as AntTable } from 'antd';
import React, { useState } from 'react';

import { RoleR } from '../../../services/roles';
import { AddOrEditModal } from './AddOrEditModal';
import { TableActions } from './TableAction';

type TableProps = {
  roles: RoleR[];
  loadingTable: boolean;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  onEdit: () => void;
  onPaginationChange: (value: number) => void;
};

export const Table = ({
  roles,
  loadingTable,
  totalItems,
  currentPage,
  pageSize,
  onEdit,
  onPaginationChange
}: TableProps): JSX.Element => {
  const [currEditIndex, setCurrEditIndex] = useState(-1);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const closeEditModal = () => {
    setCurrEditIndex(-1);
    setEditModalVisible(false);
  };

  const openEditModal = (index: number) => {
    setCurrEditIndex(index);
    setEditModalVisible(true);
  };

  const columns = [
    {
      title: 'ID #',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Label',
      dataIndex: 'label'
    },
    {
      title: 'Actions',
      width: 150,
      key: 'operation',
      align: 'center' as const,
      render: (_: any, __: RoleR, index: number) => {
        return <TableActions onEditClick={() => openEditModal(index)} />;
      }
    }
  ];

  return (
    <div>
      <AntTable
        style={{ marginTop: '3%' }}
        loading={loadingTable}
        rowKey={({ id }) => id}
        dataSource={roles}
        columns={columns}
        pagination={false}
        scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
        expandRowByClick={true}
        onRow={(_, index) => ({
          onClick: () => openEditModal(index!)
        })}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />

      {currEditIndex !== -1 && (
        <AddOrEditModal
          modalVisible={editModalVisible}
          role={roles[currEditIndex]}
          onCancel={closeEditModal}
          onSuccess={() => {
            closeEditModal();
            onEdit();
          }}
        />
      )}
    </div>
  );
};
