import { PlatformEnum } from '../types/enums';
import { callApi } from './api';

export type Values = {
  name: string;
  type: 'str' | 'bool';
  multi?: boolean;
  values?: string[];
};

export type Attribute = { attributes: Values[] };

export type PermissionMR = {
  id: number;
  name: string;
  params?: Attribute;
  platform: PlatformEnum;
};

class PermissionsService {
  async getAll(): Promise<PermissionMR[]> {
    return callApi({ url: '/permissions/' });
  }
}

export const permissionsService = new PermissionsService();
