import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useUpdate } from 'react-use';

import { Spinner } from './components/Spinner';
import { DashboardLayout } from './layout';
import { Login } from './pages/login';
import { PageNotFound } from './pages/page-not-found';
import { AuthenticatedRoute, UnauthenticatedRoute } from './routes/guards';
import { authService } from './services/auth';
import { authState } from './services/auth-state';

function Routes() {
  const forceUpdate = useUpdate();

  useEffect(() => {
    authState.registerSubscriber(forceUpdate); // by forcing rerender when loggedin state changes UnauthenticatedRoute and AuthenticatedRoute can redirect properly
    return () => authState.unregisterSubscriber(forceUpdate);
  }, [forceUpdate]);

  return (
    <Switch>
      <Route
        exact
        path="/page-not-found"
        render={({ location }) => {
          const from: string = (location.state as any)?.from ?? '';
          return <PageNotFound from={from} />;
        }}
      />
      <UnauthenticatedRoute exact path="/login" component={Login} />
      <AuthenticatedRoute path="/" component={DashboardLayout} />
    </Switch>
  );
}

export function App(): JSX.Element {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      await authService.loadLoggedInState();
      setLoading(false);
    }

    load();
  }, []);

  return isLoading ? (
    <Spinner size="large" height="100vh" />
  ) : (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}
