import { Layout, Row, Col, Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { usePromise } from 'react-use';

import { Spinner } from '../../components/Spinner';
import { authService } from '../../services/auth';
import { useEffectTrigger } from '../../utils/hooks/useEffectTrigger';
import { showApiErrors } from '../../utils/showApiErrors';

const UserMenu = ({ userFullName }: { userFullName: string }) => {
  const [loading, setLoading] = useState(false);
  const mounted = usePromise();

  const onLogout = async (): Promise<void> => {
    setLoading(true);

    try {
      await mounted(authService.logout()); // Returns 'Warning: Can't perform a React state update on an unmounted component...' without mounted
    } catch (e) {
      showApiErrors(e as any);
    } finally {
      setLoading(false);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item>
        {/* <LogoutOutlined /> */}
        {loading ? (
          <Spinner size="small" height="32px" />
        ) : (
          <div
            onClick={() => {
              onLogout();
            }}
          >
            Logout
          </div>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div style={{ float: 'right', marginRight: 20, padding: '0 20px', cursor: 'pointer' }}>
        <span>{userFullName}</span>
      </div>
    </Dropdown>
  );
};

const HeaderUserInfo = () => {
  const [userFullName, setUserFullName] = useState('');
  const [userInfoRefreshTrigger, activateUserInfoRefreshTrigger] = useEffectTrigger();

  useEffect(() => {
    const { firstName, lastName } = authService.getUserInfo();
    setUserFullName(`${firstName} ${lastName}`);
  }, [userInfoRefreshTrigger]);

  useEffect(() => {
    authService.registerSubscriber(activateUserInfoRefreshTrigger);
    return () => authService.unregisterSubscriber(activateUserInfoRefreshTrigger);
  }, [activateUserInfoRefreshTrigger]);

  return <UserMenu userFullName={userFullName} />;
};

export const Header = (): JSX.Element => {
  return (
    <Layout.Header style={{ background: '#fff', padding: 0 }}>
      <Row style={{ flexWrap: 'nowrap', width: '100%' }}>
        <Col style={{ width: '100%', marginRight: '15px' }} />
        <Col style={{ width: '300px' }}>
          <HeaderUserInfo />
        </Col>
      </Row>
    </Layout.Header>
  );
};
