import { CloseCircleTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Col, Collapse, Form, Input, InputNumber, Modal, Row, Select, Space } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';

import { elementWithLoading } from '../../../components/LoadingWrapper';
import { PermissionMR, permissionsService, Values } from '../../../services/permission';
import { RoleCU, RoleR, rolesService } from '../../../services/roles';
import { PlatformEnum } from '../../../types/enums';
import { Attributes, RolePermissionCU } from '../../../types/types';
import { confirmClose } from '../../../utils/confirmClose';
import { useEffectTrigger } from '../../../utils/hooks/useEffectTrigger';
import { useForm } from '../../../utils/hooks/useForm';
import { openSuccessNotification } from '../../../utils/notifications';
import { generateRoleOptions } from '../../../utils/options';
import { formatPermissionsByPlatform, PlatformPanelHeaders } from '../../../utils/permissions';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from './AddOrEditModal.module.css';

const { Panel } = Collapse;

type AddOrEditModalProps = {
  modalVisible: boolean;
  role?: RoleR;
  onCancel: () => void;
  onSuccess: () => void;
};

const FORM_KEY_PERMISSIONS = 'permissions';
const FORM_KEY_PERMISSION_SELECTED = 'permissionSelected';
const FORM_KEY_PERMISSION_ID = 'permissionId';
const FORM_KEY_PARAMS = 'params';
const FORM_KEY_PARAMS_NAME = 'name';
const FORM_KEY_PARAMS_VALUE = 'value';
const FORM_KEY_ROLES = 'roles';
const FORM_KEY_NAME = 'name';

type AttributeParams = {
  [FORM_KEY_PARAMS_NAME]: string;
  [FORM_KEY_PARAMS_VALUE]: string;
};
type Permission = {
  [FORM_KEY_PARAMS]?: AttributeParams[];
  [FORM_KEY_PERMISSION_ID]: number;
  [FORM_KEY_PERMISSION_SELECTED]: boolean;
};

type PermissionsPerPlatform = { [key in PlatformEnum]?: Permission[] };
type FormValues = {
  [FORM_KEY_PERMISSIONS]: PermissionsPerPlatform;
  [FORM_KEY_NAME]: string;
  [FORM_KEY_ROLES]?: number[];
};

export const AddOrEditModal = ({ modalVisible, role, onCancel, onSuccess }: AddOrEditModalProps): JSX.Element => {
  const [, activateFormChangedTrigger] = useEffectTrigger();

  const [form] = useForm(activateFormChangedTrigger);

  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [roles, setRoles] = useState<RoleR[]>([]);
  const [permissions, setPermissions] = useState<PermissionMR[]>([]);

  const [loading, setLoading] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);

  const titleAndBtnText = `${role ? 'Edit' : 'Add'} Role`;

  const handleCancel = () => {
    wasFormChanged ? confirmClose(onCancel) : onCancel();
  };

  const permissionsPerPlatform = useMemo(() => formatPermissionsByPlatform(permissions), [permissions]);

  const initialValues = useMemo(() => {
    const parsedPermissions: Record<string, any> = {};
    // Initial data should be false for every at begining, so we will set it to false, add permissionId and index to pair with `permissionsPerPlatform`
    Object.values(PlatformEnum).forEach((platformName) => {
      parsedPermissions[platformName] = permissionsPerPlatform[platformName].map((el: PermissionMR, index: number) => ({
        [FORM_KEY_PERMISSION_SELECTED]: false,
        [FORM_KEY_PERMISSION_ID]: el.id,
        index
      }));
    });

    // If this is add new, we will just return this `parsedPermissions` (permissionSelected: false, without attributes)
    if (!role) {
      return { [FORM_KEY_PERMISSIONS]: parsedPermissions };
    }

    // Extract attributes from permission
    for (const element of role.permissions) {
      const platform = element.permission.platform;
      // If do not have permissions for platform exit (i.e. permissions were not loaded yet)
      if (!permissionsPerPlatform[platform].length) {
        break;
      }

      // Match with index of `permissionsPerPlatform` as it is our Form.List.fields
      const index = parsedPermissions[platform].find(
        (el: Record<string, any>) => el.permissionId === element.permission.id
      )?.index;

      // Set it to true as it is in role
      parsedPermissions[platform][index][FORM_KEY_PERMISSION_SELECTED] = true;
      // Set params if found
      const params = Object.entries(element.params ?? {}).map(([name, value]) => ({ name, value }));
      parsedPermissions[platform][index][FORM_KEY_PARAMS] = params;
    }

    return { ...role, permissions: parsedPermissions, [FORM_KEY_ROLES]: role.roles.map((el) => el.id) };
  }, [role, permissionsPerPlatform]);

  useEffect(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form, modalVisible, initialValues]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        setLoadingRoles(true);
        const data = await rolesService.getAll();
        setRoles(data);
      } catch (e) {
        showApiErrors(e as AxiosError);
      } finally {
        setLoadingRoles(false);
      }
    };

    const getPermissions = async () => {
      try {
        setLoading(true);
        const data = await permissionsService.getAll();
        setPermissions(data);
      } catch (e) {
        showApiErrors(e as AxiosError);
      } finally {
        setLoading(false);
      }
    };

    getRoles();
    getPermissions();
  }, []);

  const parseData = (values: FormValues): RoleCU => {
    const data: { name: string; roles: number[]; permissions: RolePermissionCU[] } = {
      name: values[FORM_KEY_NAME],
      roles: values[FORM_KEY_ROLES] ?? [],
      permissions: []
    };
    Object.values(PlatformEnum).forEach((platformName) => {
      const selectedPermissions =
        values[FORM_KEY_PERMISSIONS][platformName]?.filter((el) => el[FORM_KEY_PERMISSION_SELECTED]) ?? [];

      selectedPermissions.forEach((permission) => {
        const params = permission[FORM_KEY_PARAMS] ?? [];
        const permissionObject: RolePermissionCU = { permissionId: permission.permissionId, params: null };
        if (params.length) {
          permissionObject.params = params.reduce<Attributes>(
            (acc, { name, value }) => ({ ...acc, [name]: value }),
            {}
          );
        }

        data.permissions.push(permissionObject);
      });
    });
    return data;
  };

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const data = parseData(values);
      if (role) {
        await rolesService.edit(role.id, data);
        openSuccessNotification({ message: 'Role successfully edited!' });
      } else {
        await rolesService.add(data);
        openSuccessNotification({ message: 'Role successfully added!' });
      }

      onSuccess();
    } catch (e) {
      showApiErrors(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const renderAttributeFormItem = (attributes: Values[], platform: PlatformEnum, index: number, fieldName: number) => {
    const name: string | undefined = form.getFieldValue([
      FORM_KEY_PERMISSIONS,
      platform,
      index,
      FORM_KEY_PARAMS,
      fieldName,
      FORM_KEY_PARAMS_NAME
    ]);
    if (!name) {
      return null;
    }

    const attribute = attributes.find((el) => el.name === name);

    if (!attribute) {
      return null;
    }

    const attributeType = attribute.type;
    const attributeValues = attribute.values ?? [];
    const attributeIsMultiSelect = attribute.multi ?? false;

    switch (attributeType) {
      case 'bool':
        return (
          <Form.Item
            name={[fieldName, FORM_KEY_PARAMS_VALUE]}
            label="Attribute Value"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox />
          </Form.Item>
        );
      case 'str':
        return (
          <Form.Item name={[fieldName, FORM_KEY_PARAMS_VALUE]} label="Attribute Value">
            <Select
              options={attributeValues.map((value) => ({
                value,
                label: value
              }))}
              style={{ width: '100%' }}
              mode={attributeIsMultiSelect ? 'multiple' : undefined}
            />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const renderPermissionAttributes = (platform: PlatformEnum, index: number, permissionId: number) => {
    const checked = form.getFieldValue([FORM_KEY_PERMISSIONS, platform, index, FORM_KEY_PERMISSION_SELECTED]);

    if (!checked) {
      return null;
    }

    const attributes = permissions.find((el) => el.id === permissionId)!.params?.attributes;

    if (!attributes || !attributes.length) {
      return null;
    }

    return (
      <Form.List name={[FORM_KEY_PERMISSIONS, platform, index, FORM_KEY_PARAMS]}>
        {(fields, { add, remove }) => {
          return (
            <div style={{ marginLeft: '30px', marginBottom: '3%' }}>
              {fields.map((field, attributeIndex) => (
                <Row key={attributeIndex} gutter={8}>
                  <Col span={6}>
                    <Form.Item name={[field.name, FORM_KEY_PARAMS_NAME]} label="Attribute name">
                      <Select
                        options={attributes.map((el) => ({ value: el.name, label: el.name }))}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={14}>{renderAttributeFormItem(attributes, platform, index, field.name)}</Col>
                  <Col span={4} style={{ justifyContent: 'end', display: 'flex', alignItems: 'center' }}>
                    <CloseCircleTwoTone
                      twoToneColor="#FF6347"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Col>
                </Row>
              ))}
              <Button onClick={() => add()}>Add new attribute</Button>
            </div>
          );
        }}
      </Form.List>
    );
  };

  const formElement = (
    <Form
      form={form}
      onFinish={(values) => void handleSubmit(values)}
      onFinishFailed={({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
      }}
      onValuesChange={() => {
        setWasFormChanged(true);
        activateFormChangedTrigger();
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={32}>
        <Col span={6}>
          <Form.Item
            name={FORM_KEY_NAME}
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please input name!'
              }
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={FORM_KEY_ROLES} label="Roles">
            <Select
              placeholder="Roles"
              mode="multiple"
              allowClear
              showSearch // Default showSearch value for multi-select is true, but we will set it explicitly, just in case...
              optionFilterProp="data-searchvalue"
              loading={loadingRoles}
              style={{ width: '100%' }}
            >
              {generateRoleOptions(roles)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Space direction="vertical" style={{ width: '100%' }}>
        {Object.values(PlatformEnum).map((key) => {
          return (
            <Collapse key={key} defaultActiveKey={Object.values(PlatformEnum)}>
              <Panel header={PlatformPanelHeaders[key]} key={key}>
                <div key={key}>
                  <Row>
                    <Col span={24}>
                      {permissionsPerPlatform[key].map((permission: PermissionMR, index: number) => (
                        <Row key={index}>
                          <Col span={24}>
                            <Form.Item
                              name={[FORM_KEY_PERMISSIONS, key, index, FORM_KEY_PERMISSION_SELECTED]}
                              label={permission.name}
                              className={styles.rowReverse}
                              colon={false}
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                            {form.getFieldValue([FORM_KEY_PERMISSIONS, key, index, FORM_KEY_PERMISSION_SELECTED]) && (
                              <Form.Item
                                name={[FORM_KEY_PERMISSIONS, key, index, FORM_KEY_PERMISSION_ID]}
                                hidden={true}
                              >
                                <InputNumber />
                              </Form.Item>
                            )}
                          </Col>

                          <Col span={24}>
                            <Row>
                              <Col span={24}>
                                {renderPermissionAttributes(key as PlatformEnum, index, permission.id)}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </div>
              </Panel>
            </Collapse>
          );
        })}
      </Space>
      <Row gutter={32}>
        <Col className={styles.submitBtnCol} span={24}>
          <Form.Item>
            <Button className={styles.submitBtn} type="primary" htmlType="submit">
              {titleAndBtnText}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
  return (
    <Modal
      title={titleAndBtnText}
      wrapClassName="vertical-center-modal"
      open={modalVisible}
      onCancel={handleCancel}
      footer={null}
      width="80%"
    >
      {elementWithLoading(formElement, loading)}
    </Modal>
  );
};
