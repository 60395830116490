import { EventEmitter, SubscriberFunction } from '../utils/event-emitter';

type SubscriberArgs = [loggedIn: boolean];

export class AuthState {
  private loggedIn = false;

  private readonly eventEmitter = new EventEmitter<SubscriberArgs>();

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  setLoggedIn(loggedIn: boolean): void {
    const prevLoggedIn = this.loggedIn;
    this.loggedIn = loggedIn;

    if (prevLoggedIn !== loggedIn) {
      this.eventEmitter.dispatchEvent(loggedIn);
    }
  }

  registerSubscriber(subscriber: SubscriberFunction<SubscriberArgs>): void {
    this.eventEmitter.registerSubscriber(subscriber);
  }

  unregisterSubscriber(subscriber: SubscriberFunction<SubscriberArgs>): void {
    this.eventEmitter.unregisterSubscriber(subscriber);
  }
}

export const authState = new AuthState();
