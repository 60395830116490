import { FormInstance } from 'antd/es/form/Form';
import { useForm as useAntdForm } from 'antd/lib/form/Form';
import { useMemo } from 'react';

export function useForm(changeCallback: () => void, form?: FormInstance): [FormInstance] {
  const [antdForm] = useAntdForm();

  const formInstance = form ?? antdForm;

  const wrapForm = useMemo(
    () => ({
      ...formInstance,
      resetFields: (fields?: any) => {
        formInstance.resetFields(fields);
        changeCallback();
      },
      setFields: (fields: any) => {
        formInstance.setFields(fields);
        changeCallback();
      },
      setFieldsValue: (values: any) => {
        formInstance.setFieldsValue(values);
        changeCallback();
      }
    }),
    [formInstance, changeCallback]
  );

  return [wrapForm];
}
