import { Layout, Menu } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getAllowedRoutes, Route } from '../../routes';
import { BooleanStringValue } from '../../types/enums';

type SidebarProps = {
  isSmallScreen: boolean;
};

const generateSidebarMenu = (routes: Route[], parentPath = '') => {
  return routes.map((route) => {
    if (route.skipRender) {
      return null;
    }

    const path = route.path;

    if (route.children) {
      return route.children.length > 0 ? (
        <Menu.SubMenu
          key={path}
          title={
            <span>
              {route.icon}
              <span>{route.title}</span>
            </span>
          }
        >
          {generateSidebarMenu(route.children, path)}
        </Menu.SubMenu>
      ) : null;
    }

    const fullPath = parentPath + path;

    return (
      <Menu.Item key={fullPath}>
        <Link to={fullPath}>
          {!parentPath && route.icon}
          <span>{route.title}</span>
        </Link>
      </Menu.Item>
    );
  });
};

export const Sidebar = ({ isSmallScreen }: SidebarProps): JSX.Element => {
  const location = useLocation();
  const wasCollapsed = localStorage.getItem('collapse');
  const [collapsed, setCollapsed] = useState(
    wasCollapsed ? wasCollapsed === BooleanStringValue.True : window.innerWidth < 1400
  );
  const routes = getAllowedRoutes();

  const defaultOpenKeys = useMemo(() => routes.map((route) => route.path), [routes]);

  const onCollapse = (isCollapsed: boolean): void => {
    setCollapsed(isCollapsed);
    localStorage.setItem('collapse', isCollapsed.toString());
  };

  const breakpoint = 'lg';

  const additionalProps = isSmallScreen ? {} : ({ breakpoint } as const);
  return (
    <Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse} {...additionalProps}>
      <Menu theme="dark" mode="inline" defaultOpenKeys={defaultOpenKeys} selectedKeys={[location.pathname]}>
        {generateSidebarMenu(routes)}
      </Menu>
    </Layout.Sider>
  );
};
