import { authService } from '../services/auth';

export const handleRedirectLogin = async (serviceRedirectUrl: string, clientId?: string): Promise<void> => {
  let locationHref = serviceRedirectUrl;

  if (clientId) {
    const code = await authService.generateOneTimeCode(clientId);
    locationHref += `?code=${code}`;
  }

  window.location.href = locationHref;
};
