import { Col, Collapse, Modal, Row, Spin } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { usePromise } from 'react-use';

import { UserPermission, usersService } from '../../../services/users';
import { BooleanStringValue, PlatformEnum } from '../../../types/enums';
import { PlatformPanelHeaders, formatPermissionsByPlatform } from '../../../utils/permissions';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from './ViewPermissionsModal.module.css';

const { Panel } = Collapse;

type Props = {
  modalVisible: boolean;
  userId: number;
  onClose: () => void;
};

const getFormattedAttributeValue = (attributeValue: any) => {
  switch (attributeValue) {
    case typeof attributeValue === 'string':
      return attributeValue;
    case typeof attributeValue === 'boolean':
      return attributeValue ? BooleanStringValue.True : BooleanStringValue.False;
    default:
      return attributeValue.toString().replaceAll(',', ', ');
  }
};

export const ViewPermissionsModal = ({ modalVisible, userId, onClose }: Props): JSX.Element => {
  const [userPermissions, setUserPermissions] = useState<UserPermission[]>([]);
  const [loadingUserPermissions, setLoadingUserPermissions] = useState(false);

  const mounted = usePromise();

  useEffect(() => {
    (async () => {
      try {
        setLoadingUserPermissions(true);
        const { permissions } = await mounted(usersService.getUser(userId));
        setUserPermissions(permissions);
      } catch (e) {
        showApiErrors(e as AxiosError);
      } finally {
        setLoadingUserPermissions(false);
      }
    })();
  }, [mounted, userId]);

  const permissionsPerPlatform = useMemo(() => formatPermissionsByPlatform(userPermissions), [userPermissions]);

  const renderPermissions = (): JSX.Element => {
    const platforms = Object.values(PlatformEnum);
    return (
      <Collapse defaultActiveKey={platforms}>
        {platforms.map((platform) => {
          return (
            <Panel header={PlatformPanelHeaders[platform]} key={platform}>
              {permissionsPerPlatform[platform].map(({ name, params }: UserPermission) => {
                return (
                  <Row key={name} className={styles.permissionRow}>
                    <Col span={24}>{name}</Col>

                    {params
                      ? Object.entries(params).map(([attributeName, attributeValue], index) => {
                          return (
                            <Col key={index} span={24} className={styles.permissionAttributeCol}>
                              {attributeName}: {getFormattedAttributeValue(attributeValue)}
                            </Col>
                          );
                        })
                      : null}
                  </Row>
                );
              })}
            </Panel>
          );
        })}
      </Collapse>
    );
  };

  return (
    <Modal
      title="Permissions"
      wrapClassName="vertical-center-modal"
      open={modalVisible}
      onCancel={onClose}
      footer={null}
      width={500}
      forceRender
    >
      <Spin spinning={loadingUserPermissions}>{renderPermissions()}</Spin>
    </Modal>
  );
};
