import { Input } from 'antd';
import React from 'react';

type Props = {
  id?: string;
  name?: string;
  placeholder?: string;
  width?: number | string;
  value: string;
  onChange: (value: string) => void;
};

export const SearchBar = ({ id, name, placeholder, width, value, onChange }: Props): JSX.Element => {
  return (
    <div style={{ width: width ?? '100%' }}>
      <Input.Search
        id={id ?? 'search'}
        name={name ?? 'search'}
        placeholder={placeholder ?? 'Search'}
        style={{ width: '100%' }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
