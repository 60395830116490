import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';

import { elementWithLoading } from '../../../components/LoadingWrapper';
import { RoleR, rolesService } from '../../../services/roles';
import { UserC, UserR, usersService, UserU } from '../../../services/users';
import { confirmClose } from '../../../utils/confirmClose';
import { FileUpload } from '../../../utils/fileUpload';
import { openSuccessNotification } from '../../../utils/notifications';
import { generateRoleOptions } from '../../../utils/options';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from './AddOrEditModal.module.css';

type AddOrEditModalProps = {
  modalVisible: boolean;
  user?: UserR;
  onCancel: () => void;
  onSuccess: () => void;
};

export const AddOrEditModal = ({ modalVisible, user, onCancel, onSuccess }: AddOrEditModalProps): JSX.Element => {
  const [form] = Form.useForm();

  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [roles, setRoles] = useState<RoleR[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);

  const titleAndBtnText = `${user ? 'Edit' : 'Add'} User`;

  const handleCancel = () => {
    wasFormChanged ? confirmClose(onCancel) : onCancel();
  };

  const initialValues = useMemo(() => {
    if (!user) {
      return undefined;
    }

    return {
      ...user,
      avatar: user.avatar ? user.avatar : null,
      roles: user.roles.map((el) => el.id)
    };
  }, [user]);

  useEffect(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form, modalVisible]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        setLoadingRoles(true);
        const data = await rolesService.getAll();
        setRoles(data);
      } catch (e) {
        showApiErrors(e as AxiosError);
      } finally {
        setLoadingRoles(false);
      }
    };

    getRoles();
  }, []);

  const handleSubmit = async (values: UserC | UserU) => {
    try {
      setLoading(true);
      if (user) {
        await usersService.edit(user.id, values);
        openSuccessNotification({ message: 'User successfully edited!' });
      } else {
        await usersService.add(values);
        openSuccessNotification({ message: 'User successfully added!' });
      }

      onSuccess();
    } catch (e) {
      showApiErrors(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const formElement = (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values: UserC | UserU) => void handleSubmit(values)}
      onFinishFailed={({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
      }}
      onValuesChange={() => setWasFormChanged(true)}
      initialValues={initialValues}
    >
      <Row gutter={32}>
        <Col span={6}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please input email!'
              },
              {
                type: 'email',
                message: 'Please use correct email format!'
              },
              {
                max: 250,
                message: 'Email max length is 250!'
              }
            ]}
          >
            <Input placeholder="Email" disabled={!!user} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="firstName"
            label="First name"
            rules={[
              {
                required: true,
                message: 'Please input first name!'
              },
              {
                max: 250,
                message: 'First name max length is 250!'
              }
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="lastName"
            label="Last name"
            rules={[
              {
                required: true,
                message: 'Please input last name!'
              },
              {
                max: 250,
                message: 'Last name max length is 250!'
              }
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="legalEmail" label="Legal Email">
            <Input placeholder="Legal Email" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        {!user && (
          <Col span={6}>
            <Form.Item name="password" label="Password">
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
        )}

        <Col span={6}>
          <Form.Item name="slackId" label="Slack ID">
            <Input placeholder="Slack ID" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="roles" label="Roles">
            <Select
              placeholder="Roles"
              mode="multiple"
              allowClear
              showSearch // Default showSearch value for multi-select is true, but we will set it explicitly, just in case...
              optionFilterProp="data-searchvalue"
              loading={loadingRoles}
            >
              {generateRoleOptions(roles)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="avatar">
            <FileUpload />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col className={styles.submitBtnCol} span={24}>
          <Form.Item>
            <Button className={styles.submitBtn} type="primary" htmlType="submit">
              {titleAndBtnText}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Modal
      title={titleAndBtnText}
      wrapClassName="vertical-center-modal"
      open={modalVisible}
      onCancel={handleCancel}
      footer={null}
      width="80%"
    >
      {elementWithLoading(formElement, loading)}
    </Modal>
  );
};
