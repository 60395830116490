import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Pagination, Table as AntTable } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import { UserR } from '../../../services/users';
import { AddOrEditModal } from './AddOrEditModal';
import { UsersTableActions } from './TableAction';

type TableProps = {
  users: UserR[];
  loadingTable: boolean;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  onEdit: () => void;
  onDeactivate: () => void;
  onActivate: () => void;
  onPaginationChange: (value: number) => void;
};

export const Table = ({
  users,
  loadingTable,
  totalItems,
  currentPage,
  pageSize,
  onEdit,
  onDeactivate,
  onActivate,
  onPaginationChange
}: TableProps): JSX.Element => {
  const [currEditIndex, setCurrEditIndex] = useState(-1);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const closeEditModal = () => {
    setCurrEditIndex(-1);
    setEditModalVisible(false);
  };

  const openEditModal = (index: number) => {
    setCurrEditIndex(index);
    setEditModalVisible(true);
  };

  const columns = [
    {
      title: 'ID #',
      dataIndex: 'id'
    },
    {
      title: 'First name',
      dataIndex: 'firstName'
    },
    {
      title: 'Last name',
      dataIndex: 'lastName'
    },
    {
      title: 'Date created',
      dataIndex: 'createdDatetime',
      align: 'center' as const,
      render: (date: string) => dayjs(date).format('YYYY-MM-DD')
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Legal Email',
      dataIndex: 'legalEmail'
    },
    {
      title: 'Active',
      key: 'isActive',
      align: 'center' as const,
      render: (_: any, { isActive }: UserR) => {
        return isActive ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#f81d22" />;
      }
    },
    {
      title: 'Actions',
      width: 150,
      key: 'operation',
      align: 'center' as const,
      render: (_: any, row: UserR, index: number) => {
        return (
          <UsersTableActions
            user={row}
            onEditClick={() => openEditModal(index)}
            onDeactivate={onDeactivate}
            onActivate={onActivate}
          />
        );
      }
    }
  ];

  return (
    <div>
      <AntTable
        style={{ marginTop: '3%' }}
        loading={loadingTable}
        rowKey={({ id }) => id}
        dataSource={users}
        columns={columns}
        pagination={false}
        scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
        expandRowByClick={true}
        onRow={(_, index) => ({
          onClick: () => openEditModal(index!)
        })}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />

      {currEditIndex !== -1 && (
        <AddOrEditModal
          modalVisible={editModalVisible}
          user={users[currEditIndex]}
          onCancel={closeEditModal}
          onSuccess={() => {
            closeEditModal();
            onEdit();
          }}
        />
      )}
    </div>
  );
};
