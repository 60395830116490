import { RolePermissionCU, RolePermissionR } from '../types/types';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { PagedRequestArgs, PagedResponse } from '../utils/types';
import { callApi } from './api';

export type RoleCU = {
  permissions: RolePermissionCU[];
  roles: number[];
  name: string;
};

export type RoleBasic = {
  id: number;
  name: string;
};

export type RoleR = {
  id: number;
  permissions: RolePermissionR[];
  roles: RoleBasic[];
  name: string;
  label: string;
};

class RolesService {
  async getPaged({
    page,
    size,
    cancelToken,
    searchValue
  }: PagedRequestArgs & { searchValue: string }): Promise<PagedResponse<RoleR>> {
    const query = getQueryString({ ...getOffsetLimitFromPagination({ page, size }), search: searchValue });
    return callApi({ url: `/roles/?${query}`, cancelToken });
  }

  async getAll(): Promise<RoleR[]> {
    return callApi({ url: '/roles/' });
  }

  async add(data: RoleCU) {
    return callApi({ url: '/roles/', method: 'POST', data });
  }

  async edit(id: number, data: RoleCU) {
    return callApi({ url: `/roles/${id}/`, method: 'PUT', data });
  }
}

export const rolesService = new RolesService();
