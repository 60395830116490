import { notification } from 'antd';
import React from 'react';

type NotificationArgs = {
  message: string | React.ReactNode;
  description?: string;
  duration?: number;
};

export const openNotificationWithIcon = (
  type: 'error' | 'success' | 'warning',
  message: string | React.ReactNode,
  description?: string,
  duration = 3
): void => {
  notification[type]({ message, description, duration });
};

export const openErrorNotification = ({ message, description, duration }: NotificationArgs): void => {
  openNotificationWithIcon('error', message, description, duration);
};

export const openSuccessNotification = ({ message, description, duration }: NotificationArgs): void => {
  openNotificationWithIcon('success', message, description, duration);
};
export const openWarningNotification = ({ message, description, duration }: NotificationArgs): void => {
  openNotificationWithIcon('warning', message, description, duration);
};
