import { PermissionMR } from '../services/permission';
import { UserPermission } from '../services/users';
import { PlatformEnum } from '../types/enums';

export const PlatformPanelHeaders = Object.entries(PlatformEnum).reduce<Record<string, string>>((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

export const formatPermissionsByPlatform = (permissions: PermissionMR[] | UserPermission[]): Record<string, any> => {
  // Make object where key is platformName
  const permissionsPerPlatformObject: Record<string, any> = {};
  // Generate list of permissions for each platform
  Object.values(PlatformEnum).forEach((platformKey) => {
    permissionsPerPlatformObject[platformKey] = permissions.filter((el) => {
      return el.platform === platformKey;
    });
  });

  return permissionsPerPlatformObject;
};
