import qs from 'query-string';

export const getQueryString = (queryParams?: Record<string, any>): string => {
  if (!queryParams) {
    return '';
  }

  return qs.stringify(queryParams, { skipEmptyString: true, skipNull: true, arrayFormat: 'none' });
};

type PaginationArgs = {
  page: number;
  size: number;
};

type PaginationQuery = {
  limit: number;
  offset: number;
};

export const getOffsetLimitFromPagination = ({ page, size }: PaginationArgs): PaginationQuery => ({
  limit: size,
  offset: (page - 1) * size
});
