export enum Permission {}
export const SUPERUSER = 'superuser';

export enum PlatformEnum {
  IAM = 'iam',
  Billing = 'billing',
  Dash = 'v2_1',
  LpGenerator = 'lp_generator',
  WordlineSocial2Search = 'wordline_s2s'
}

export enum BooleanStringValue {
  True = 'true',
  False = 'false'
}
