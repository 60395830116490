import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Modal, Popover, Row, Spin } from 'antd';
import { AxiosError } from 'axios';
import React, { useState } from 'react';

import { UserR, usersService } from '../../../services/users';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { ViewPermissionsModal } from './ViewPermissionsModal';

type UsersTableActionsProps = {
  user: UserR;
  onEditClick: () => void;
  onDeactivate: () => void;
  onActivate: () => void;
};

export const UsersTableActions = ({
  user,
  onEditClick,
  onDeactivate,
  onActivate
}: UsersTableActionsProps): JSX.Element => {
  const [loadingDeactivate, setLoadingDeactivate] = useState(false);
  const [viewPermissionsModalVisible, setViewPermissionsModalVisible] = useState(false);

  const handleDeactivate = async () => {
    setLoadingDeactivate(true);
    try {
      await usersService.deactivate(user.id);
      openSuccessNotification({ message: 'Successfully deactivated user!', duration: 8 });

      onDeactivate();
    } catch (e) {
      showApiErrors(e as AxiosError);
    } finally {
      setLoadingDeactivate(false);
    }
  };

  const handleActivate = async () => {
    setLoadingDeactivate(true);
    try {
      await usersService.activate(user.id);
      openSuccessNotification({ message: 'Successfully activated user!', duration: 8 });

      onActivate();
    } catch (e) {
      showApiErrors(e as AxiosError);
    } finally {
      setLoadingDeactivate(false);
    }
  };

  const handleOpenDeactivateModal = () => {
    Modal.confirm({
      title: 'Are you sure that you want to deactivate this user?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleDeactivate(),
      onCancel: () => {}
    });
  };

  const handleOpenActivateModal = () => {
    Modal.confirm({
      title: 'Are you sure that you want to activate this user?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleActivate(),
      onCancel: () => {}
    });
  };

  return (
    <Spin spinning={loadingDeactivate}>
      <Row gutter={8} style={{ justifyContent: 'center', flexWrap: 'nowrap' }} onClick={(e) => e.stopPropagation()}>
        <Popover content="Preview/Edit" trigger="hover">
          <EditOutlined style={{ marginRight: 7, color: '#438EF7' }} onClick={onEditClick} />
        </Popover>

        <Popover content="View permissions" trigger="hover">
          <EyeOutlined style={{ marginRight: 7 }} onClick={() => setViewPermissionsModalVisible(true)} />
        </Popover>
        {viewPermissionsModalVisible && (
          <ViewPermissionsModal
            modalVisible={viewPermissionsModalVisible}
            userId={user.id}
            onClose={() => setViewPermissionsModalVisible(false)}
          />
        )}

        {user.isActive ? (
          <Popover content="Deactivate" trigger="hover">
            <CloseCircleOutlined style={{ color: 'red' }} onClick={handleOpenDeactivateModal} />
          </Popover>
        ) : (
          <Popover content="Activate" trigger="hover">
            <CheckCircleOutlined style={{ color: 'green' }} onClick={handleOpenActivateModal} />
          </Popover>
        )}
      </Row>
    </Spin>
  );
};
