import { Select } from 'antd';

const { Option } = Select;

type StringOrNumberOptional = string | number | undefined;
type StringOrNumberPropertyNames<T> = {
  [K in keyof T]: T[K] extends StringOrNumberOptional ? K : never;
}[keyof T];

export function generateOptions<T extends { name: StringOrNumberOptional; id: StringOrNumberOptional }>(
  options: T[],
  labelKey?: StringOrNumberPropertyNames<T>,
  valueKey?: StringOrNumberPropertyNames<T>,
  alternativeLabelKey?: StringOrNumberPropertyNames<T>,
  search?: string
): JSX.Element[];
export function generateOptions<T extends { id: StringOrNumberOptional }>(
  options: T[],
  labelKey: StringOrNumberPropertyNames<T>,
  valueKey?: StringOrNumberPropertyNames<T>,
  alternativeLabelKey?: StringOrNumberPropertyNames<T>,
  search?: string
): JSX.Element[];
export function generateOptions<T extends Record<string, unknown>>(
  options: T[],
  labelKey: StringOrNumberPropertyNames<T>,
  valueKey: StringOrNumberPropertyNames<T>,
  alternativeLabelKey?: StringOrNumberPropertyNames<T>,
  search?: string
): JSX.Element[];
export function generateOptions<T extends Record<string, any>>(
  options: T[],
  labelKey: StringOrNumberPropertyNames<T> = 'name' as any,
  valueKey: StringOrNumberPropertyNames<T> = 'id' as any,
  alternativeLabelKey?: StringOrNumberPropertyNames<T>,
  search?: string
): JSX.Element[] {
  return options.map((option) => {
    const valueOpt = option[valueKey];
    const labelOpt = option[labelKey];
    const alternativeLabel =
      alternativeLabelKey && option[alternativeLabelKey] ? ` (${option[alternativeLabelKey]})` : '';

    const searchForSelect = search ? search : String(valueOpt) + String(labelOpt) + String(alternativeLabel);

    return (
      <Option key={valueOpt} value={valueOpt} search={searchForSelect} disabled={option.disabled}>
        {`${labelOpt}${alternativeLabel}`}
      </Option>
    );
  });
}

export const generateUserOptions = (users: { id: number; firstName: string; lastName: string }[]): JSX.Element[] => {
  return users.map((user) => {
    const { id, firstName, lastName } = user;
    const display = `${firstName} ${lastName}`;

    return (
      <Option key={id} value={id} data-searchvalue={display + id.toString()}>
        {display}
      </Option>
    );
  });
};

export const generateRoleOptions = (roles: { id: number; name: string; label: string }[]): JSX.Element[] => {
  return roles.map((role) => {
    const { id, name, label } = role;
    const display = label ? `${name}_${label}` : name;

    return (
      <Option key={id} value={id} data-searchvalue={display + id.toString()}>
        {display}
      </Option>
    );
  });
};

export const generateOptionsFromObject = (options: Record<string, string>): JSX.Element[] => {
  const transformedOptions = Object.entries(options).map(([key, value]) => {
    return {
      id: value,
      name: key
    };
  });

  return generateOptions(transformedOptions);
};
