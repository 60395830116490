import axiosStatic, { AxiosRequestConfig } from 'axios';

import { API_BASE_URL } from '../config';
import { openErrorNotification } from '../utils/notifications';
import { authState } from './auth-state';
// import { addMockApiRoutes } from './mock-api';

const axiosInstance = axiosStatic.create({
  baseURL: API_BASE_URL,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      authState.setLoggedIn(false); // this will also notify app component to redirect to login page
    } else if (error.response && error.response.status === 403) {
      openErrorNotification({ message: 'You do not have permission to perform this action.' });
    }

    // TODO: some error message if something else fails (not 401 or 403) ?
    return Promise.reject(error);
  }
);

export type CancelToken = AxiosRequestConfig['cancelToken'];
export type CancelSource = { token: CancelToken; cancel: () => void };

export type CallApiArgs = {
  url: AxiosRequestConfig['url'];
  method?: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  cancelToken?: CancelToken;
  headers?: AxiosRequestConfig['headers'];
  responseType?: AxiosRequestConfig['responseType'];
};

export const callApi = ({ url, method, data, cancelToken, headers, responseType }: CallApiArgs): Promise<any> =>
  axiosInstance({ url, method: method ?? 'GET', data, cancelToken, headers, responseType }).then(
    (response) => response.data
  );

export const getCancelToken = (): CancelSource => axiosStatic.CancelToken.source();

export const isCancelled = (error: unknown): boolean => axiosStatic.isCancel(error);

// if (process.env.NODE_ENV === 'development') {
//   addMockApiRoutes(axiosInstance);
// }
