import { AxiosError } from 'axios';

import { openErrorNotification } from './notifications';

type ApiError = {
  code: string;
  message: string;
  detail?: any;
};

const firstLetterUpperCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const generateMessage = (content: any): React.ReactNode => {
  let messages: React.ReactNode[] = [];

  if (Array.isArray(content)) {
    messages = content.map((errorDetailData, index) => {
      const errorMessage = errorDetailData.ctx?.reason || firstLetterUpperCase(errorDetailData.msg);
      return (
        <div key={index}>
          {errorDetailData.loc[errorDetailData.loc.length - 1]} - {errorMessage}
        </div>
      );
    });
  } else if (typeof content === 'string') {
    messages.push(content);
  }

  return messages;
};

export const showApiErrors = (error: AxiosError<ApiError | undefined>): void => {
  let message: React.ReactNode = null;

  if (error.response) {
    if (error.response.data) {
      message = generateMessage(error.response.data.detail);
    }
  }

  const networkErrorMsg = '- check your internet connection and disable VPN!';

  openErrorNotification({
    message: error.response ? (
      <>
        <div>Request failed with status code {error.response.status}</div> {message}
      </>
    ) : (
      <>
        <div>Something went wrong {error.stack?.includes('Network Error') ? networkErrorMsg : null}</div> {error.stack}
      </>
    ),
    duration: 8
  });
};
