import Icon from '@ant-design/icons/lib/components/Icon';
import { Button, Form, Input } from 'antd';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useMount } from 'react-use';

import { PASSWORD_LOGIN_DISABLED } from '../../config';
import { authService } from '../../services/auth';
import { handleRedirectLogin } from '../../utils/login';
import { showApiErrors } from '../../utils/showApiErrors';
import styles from './Login.module.css';

type LoginFormValues = {
  email: string;
  password: string;
};

export const Login = (): JSX.Element => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const serviceRedirectUrl = params.get('service_redirect_url') ?? undefined;
  const clientId = params.get('client_id') ?? undefined;

  useMount(() => {
    const mount = async () => {
      const code = params.get('code');
      const oneLoginStateJSON = params.get('state') ?? '{}';
      const oneLoginState = JSON.parse(oneLoginStateJSON);
      const { service_redirect_url: serviceRedirectUrlFromState, client_id: clientIdFromState } = oneLoginState;

      if (code) {
        setLoading(true);
        try {
          await authService.loginWithOneloginCode(code);

          if (serviceRedirectUrlFromState) {
            handleRedirectLogin(serviceRedirectUrlFromState, clientIdFromState);
          }
        } catch (e) {
          showApiErrors(e as AxiosError);
        } finally {
          setLoading(false);
        }
      } else if (PASSWORD_LOGIN_DISABLED) {
        handleOneLogin();
      }
    };

    mount();
  });

  const handleOneLogin = async () => {
    setLoading(true);
    try {
      window.location.href = (await authService.getOneloginAuthUrl(serviceRedirectUrl, clientId)).url;
    } catch (e) {
      showApiErrors(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async ({ email, password }: LoginFormValues): Promise<void> => {
    try {
      setLoading(true);
      await authService.login(email, password);
      if (serviceRedirectUrl) {
        handleRedirectLogin(serviceRedirectUrl, clientId);
      }
    } catch (e) {
      showApiErrors(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const oneLoginIcon = (): React.ReactElement => (
    <img width="20" height="20" src="/images/one-login.png" alt="one-login-icon" />
  );

  return (
    <div className={styles.loginFormContainer}>
      <div className={styles.loginFormWrapper}>
        <img src="/images/coinis-logo.svg" alt="coinis-logo" height="50" />
        <h5 className={styles.billingText}>IAM</h5>
        <Button className={styles.oneLoginButton} type="ghost" onClick={() => void handleOneLogin()} loading={loading}>
          <Icon component={oneLoginIcon} />
          Log in with OneLogin
        </Button>
        {!PASSWORD_LOGIN_DISABLED ? (
          <>
            <br />
            <Form
              form={form}
              onFinish={(values) => void handleLogin(values)}
              onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
              }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input email!'
                  },
                  {
                    type: 'email',
                    message: 'Please use correct email format!'
                  },
                  {
                    max: 254,
                    message: 'Email max length is 254!'
                  }
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item name="password" rules={[{ required: true, message: 'Please input your password' }]}>
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <div className={styles.buttonsWrapper}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </div>
            </Form>
          </>
        ) : null}
      </div>
    </div>
  );
};
