/* eslint-disable camelcase */
import { Permission, SUPERUSER } from '../types/enums';
import { EventEmitter, SubscriberFunction } from '../utils/event-emitter';
import { getQueryString } from '../utils/query';
import { callApi } from './api';
import { authState } from './auth-state';

export type CurrentUser = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  slackId: string;
  permissions: { name: Permission | typeof SUPERUSER; params: any }[];
};

type OneloginUrl = {
  url: string;
};

export class AuthService {
  private user: CurrentUser = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    slackId: '',
    permissions: []
  };

  private readonly eventEmitter = new EventEmitter();

  async getOneloginAuthUrl(serviceRedirectUrl?: string, clientId?: string): Promise<OneloginUrl> {
    const query = getQueryString({ service_redirect_url: serviceRedirectUrl, client_id: clientId });

    return callApi({ url: `/auth/onelogin_auth_url/?${query}` });
  }

  async loginWithOneloginCode(code: string): Promise<void> {
    const response = await callApi({ url: '/auth/onelogin_auth_code/', method: 'POST', data: { code } });
    authState.setLoggedIn(true);
    return response;
  }

  async generateOneTimeCode(clientId: string): Promise<string> {
    return callApi({ url: '/auth/generate_one_time_code/', method: 'POST', data: { client_id: clientId } });
  }

  async login(email: string, password: string): Promise<void> {
    await callApi({ url: '/auth/login/', method: 'POST', data: { email, password } });
    authState.setLoggedIn(true);
  }

  async logout(): Promise<void> {
    await callApi({ url: '/auth/login/', method: 'DELETE' });
    authState.setLoggedIn(false);
  }

  async loadLoggedInState(): Promise<void> {
    try {
      await callApi({ url: '/auth/login/' });
      authState.setLoggedIn(true);
    } catch {
      authState.setLoggedIn(false);
    }
  }

  async loadUserInfo(): Promise<void> {
    this.user = await callApi({ url: '/users/me/?platform=iam' });
    this.eventEmitter.dispatchEvent();
  }

  hasPermission(permission?: Permission | ''): boolean {
    // Empty string for admin only routes
    return (
      permission === undefined ||
      !!this.user.permissions.find((perm) => perm.name === SUPERUSER) ||
      !!this.user.permissions.find((perm) => perm.name === Permission)
    );
  }

  getUserInfo(): CurrentUser {
    return this.user;
  }

  registerSubscriber(subscriber: SubscriberFunction): void {
    this.eventEmitter.registerSubscriber(subscriber);
  }

  unregisterSubscriber(subscriber: SubscriberFunction): void {
    this.eventEmitter.unregisterSubscriber(subscriber);
  }
}

export const authService = new AuthService();
