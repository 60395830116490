import { useEffect, useState } from 'react';

import { usersService } from '../../services/users';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { useFetch } from '../../utils/hooks/useFetch';
import { Header } from './components/Header';
import { Table } from './components/Table';

export const Users = (): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 25;

  const debouncedSearchValue = useDebounce(searchValue);

  const loadUsers = (cancelToken: any) =>
    usersService.getPaged({
      page: currentPage,
      size: pageSize,
      searchValue: debouncedSearchValue,
      cancelToken
    });

  const [
    {
      data: { results: users, count: totalItems },
      isFetching
    },
    getUsers
  ] = useFetch(loadUsers);

  useEffect(() => setCurrentPage(1), [debouncedSearchValue]);

  useEffect(() => {
    getUsers();
  }, [debouncedSearchValue, currentPage, getUsers]);

  return (
    <div>
      <Header searchValue={searchValue} onSearch={(value: string) => setSearchValue(value)} onAdd={getUsers} />

      <Table
        currentPage={currentPage}
        pageSize={pageSize}
        onPaginationChange={(page: number) => setCurrentPage(page)}
        users={users}
        loadingTable={isFetching}
        totalItems={totalItems}
        onEdit={getUsers}
        onDeactivate={getUsers}
        onActivate={getUsers}
      />
    </div>
  );
};
