import { EditOutlined } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';
import React from 'react';

type TableActionsProps = {
  onEditClick: () => void;
};

export const TableActions = ({ onEditClick }: TableActionsProps): JSX.Element => {
  return (
    <Row gutter={8} style={{ justifyContent: 'center' }}>
      <Col span={12}>
        <Popover content="Preview/Edit" trigger="hover">
          <EditOutlined style={{ marginRight: 7, color: '#438EF7' }} onClick={onEditClick} />
        </Popover>
      </Col>
    </Row>
  );
};
