import { DashboardOutlined, UserOutlined } from '@ant-design/icons';
import React, { ComponentType } from 'react';

import { Dashboard } from '../pages/dashboard';
import { Roles } from '../pages/roles';
import { Users } from '../pages/users';
import { authService } from '../services/auth';
import { Permission } from '../types/enums';

export type Route = {
  title: string;
  path: string;
  component?: ComponentType;
  icon?: JSX.Element;
  children?: Route[];
  skipRender?: boolean;
  permission?: Permission | '';
};

export const routes: Route[] = [
  {
    title: 'Dashboard',
    path: '/',
    component: Dashboard,
    icon: <DashboardOutlined />
  },
  {
    title: 'Roles',
    path: '/roles',
    component: Roles,
    permission: '',
    icon: <UserOutlined />
  },
  {
    title: 'Users',
    path: '/users',
    component: Users,
    permission: '',
    icon: <UserOutlined />
  }
];

export const getAllowedRoutes = (): Route[] => {
  const filterByPermission = (routesToFilter: Route[]) =>
    routesToFilter.filter((route) => authService.hasPermission(route.permission));

  const filteredRoutes = filterByPermission(routes);

  return filteredRoutes.map((route) => {
    if (route.children) {
      return { ...route, children: filterByPermission(route.children) };
    }

    return route;
  });
};
