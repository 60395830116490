export type SubscriberFunction<T extends unknown[] = []> = (...args: T) => void;

export class EventEmitter<T extends unknown[] = []> {
  private readonly subscribers: SubscriberFunction<T>[] = [];

  registerSubscriber(subscriber: SubscriberFunction<T>): void {
    this.subscribers.push(subscriber);
  }

  unregisterSubscriber(subscriber: SubscriberFunction<T>): void {
    const index = this.subscribers.indexOf(subscriber);
    if (index >= 0) {
      this.subscribers.splice(index, 1);
    }
  }

  dispatchEvent(...args: T): void {
    // notify subscribers, but we make it async with Promise.resolve, just in case:
    Promise.resolve().then(() => {
      this.subscribers.forEach((subscriber) => {
        subscriber(...args);
      });
    });
  }
}
