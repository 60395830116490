// inspired by https://github.com/schettino/react-request-hook

import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CancelSource, CancelToken, getCancelToken, isCancelled } from '../../services/api';
import { showApiErrors } from '../showApiErrors';
import { PagedResponse } from '../types';

type RequestFn<T, U extends unknown[]> = (cancelToken: CancelToken, ...rest: U) => Promise<T>;

type UseFetchReturn<T, U extends unknown[]> = readonly [
  {
    readonly data: T;
    readonly isFetching: boolean;
    readonly cancel: () => void;
  },
  (...args: U) => void
];

function useRequest<T, U extends unknown[]>(fn: RequestFn<T, U>) {
  const sourceRef = useRef<CancelSource | null>(null);
  const callFn = useRef(fn);
  useEffect(() => {
    callFn.current = fn;
  }, [fn]);

  const prepareRequest = useCallback((...args: U) => {
    const cancelSource = getCancelToken();

    const triggerRequest = () => {
      sourceRef.current = cancelSource;
      return callFn.current(cancelSource.token, ...args);
    };

    return {
      triggerRequest,
      cancel: cancelSource.cancel
    };
  }, []);

  const cancelPreviousRequest = useCallback(() => {
    if (sourceRef.current) {
      sourceRef.current.cancel();
    }
  }, []);

  useEffect(() => {
    return () => {
      cancelPreviousRequest();
    };
  }, [cancelPreviousRequest]);

  return [
    {
      cancelPreviousRequest
    },
    prepareRequest
  ] as const;
}

export function useFetch<T extends PagedResponse<unknown>, U extends unknown[]>(
  fn: RequestFn<T, U>,
  initialData?: T
): UseFetchReturn<T, U>;
export function useFetch<T, U extends unknown[]>(fn: RequestFn<T, U>, initialData: T): UseFetchReturn<T, U>;
export function useFetch<T, U extends unknown[]>(
  fn: RequestFn<T, U>,
  initialData: T = { items: [], total: 0 } as any
): UseFetchReturn<T, U> {
  const [data, setData] = useState(initialData);
  const [fetchingCount, setFetchingCount] = useState(0); // int instead of boolean because when canceling request, new request is started before loading indicator of previous request is cleared
  const [{ cancelPreviousRequest }, prepareRequest] = useRequest(fn);

  const request = useCallback(
    (...args: U) => {
      cancelPreviousRequest();
      const { triggerRequest } = prepareRequest(...args);

      (async function flow() {
        try {
          setFetchingCount((count) => count + 1);
          const fetchedData = await triggerRequest(); // fetch data
          setData(fetchedData);
        } catch (error) {
          if (!isCancelled(error)) {
            showApiErrors(error as AxiosError);
          }
        } finally {
          setFetchingCount((count) => count - 1);
        }
      })();
    },
    [prepareRequest, cancelPreviousRequest]
  );

  return useMemo(() => {
    const cancel = () => {
      cancelPreviousRequest();
    };

    return [{ data, isFetching: !!fetchingCount, cancel }, request] as const;
  }, [data, fetchingCount, request, cancelPreviousRequest]);
}
