import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { PagedRequestArgs, PagedResponse } from '../utils/types';
import { callApi } from './api';
import { PermissionMR } from './permission';
import { RoleR } from './roles';

export type UserR = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  legalEmail?: string;
  isActive: boolean;
  slackId?: string;
  avatar?: File;
  createdDatetime: string;
  createdById?: number;
  roles: RoleR[];
};

export type UserC = {
  email: string;
  firstName: string;
  lastName: string;
  legalEmail?: string;
  slackId?: string;
  avatar?: File;
  password?: string;
  roles: number[];
};

export type UserU = {
  email: string;
  firstName: string;
  lastName: string;
  legalEmail?: string;
  slackId?: string;
  avatar?: File;
  roles: number[];
};

export type UserPermission = Omit<PermissionMR, 'params'> & {
  params?: Record<string, any>;
};

export type UserWithPermissions = UserR & {
  permissions: UserPermission[];
};

class UsersService {
  async getPaged({
    page,
    size,
    cancelToken,
    searchValue
  }: PagedRequestArgs & { searchValue: string }): Promise<PagedResponse<UserR>> {
    const query = getQueryString({ ...getOffsetLimitFromPagination({ page, size }), search: searchValue });
    return callApi({ url: `/users/?${query}`, cancelToken });
  }

  async getAll(): Promise<UserR[]> {
    return callApi({ url: '/users/' });
  }

  async getUser(id: number): Promise<UserWithPermissions> {
    return callApi({ url: `/users/${id}/with_permissions/` });
  }

  async add(data: UserC) {
    const { avatar, email, firstName, lastName, legalEmail, slackId, password, roles = [] } = data;

    const formData = new FormData();

    formData.append('email', email);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    legalEmail && formData.append('legalEmail', legalEmail);
    slackId && formData.append('slackId', slackId);
    roles.forEach((el) => {
      formData.append('roles', el.toString());
    });

    if (password) {
      formData.append('password', password);
    }

    if (avatar) {
      formData.append('avatar', avatar, avatar.name);
    }

    return callApi({ url: '/users/', method: 'POST', data: formData });
  }

  async edit(id: number, data: UserU) {
    const { avatar, email, firstName, lastName, legalEmail, slackId, roles = [] } = data;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);

    legalEmail && formData.append('legalEmail', legalEmail);
    slackId && formData.append('slackId', slackId);
    roles.forEach((el) => {
      formData.append('roles', el.toString());
    });

    if (avatar && avatar instanceof File) {
      formData.append('avatar', avatar, avatar.name);
    }

    return callApi({ url: `/users/${id}/`, method: 'PUT', data: formData });
  }

  async deactivate(id: number) {
    return callApi({ url: `/users/${id}/deactivate/`, method: 'POST' });
  }

  async activate(id: number) {
    return callApi({ url: `/users/${id}/activate/`, method: 'POST' });
  }
}

export const usersService = new UsersService();
