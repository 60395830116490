import { useEffect, useState } from 'react';

import { rolesService } from '../../services/roles';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { useFetch } from '../../utils/hooks/useFetch';
import { Header } from './components/Header';
import { Table } from './components/Table';

export const Roles = (): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 25;

  const debouncedSearchValue = useDebounce(searchValue);

  const loadRoles = (cancelToken: any) =>
    rolesService.getPaged({
      page: currentPage,
      size: pageSize,
      searchValue: debouncedSearchValue,
      cancelToken
    });

  const [
    {
      data: { results: roles, count: totalItems },
      isFetching
    },
    getRoles
  ] = useFetch(loadRoles);

  useEffect(() => setCurrentPage(1), [debouncedSearchValue]);

  useEffect(() => {
    getRoles();
  }, [debouncedSearchValue, currentPage, getRoles]);

  return (
    <div>
      <Header searchValue={searchValue} onSearch={(value: string) => setSearchValue(value)} onAdd={getRoles} />

      <Table
        currentPage={currentPage}
        pageSize={pageSize}
        onPaginationChange={(page: number) => setCurrentPage(page)}
        roles={roles}
        loadingTable={isFetching}
        totalItems={totalItems}
        onEdit={getRoles}
      />
    </div>
  );
};
