import { Layout } from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import Media from 'react-media';
import { usePromise } from 'react-use';

import { Spinner } from '../components/Spinner';
import { getAllowedRoutes } from '../routes';
import { SwitchRoutes } from '../routes/SwitchRoutes';
import { authService } from '../services/auth';
import { showApiErrors } from '../utils/showApiErrors';
import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import styles from './Layout.module.css';

export const DashboardLayout = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const mounted = usePromise();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await mounted(authService.loadUserInfo());
      } catch (e) {
        showApiErrors(e as AxiosError);
      } finally {
        setLoading(false);
      }
    })();
  }, [mounted]);

  return (
    <div className={styles.layoutWrapper}>
      {loading ? (
        <Spinner size="large" height="100vh" />
      ) : (
        <Layout style={{ minHeight: '100vh', zIndex: 2 }}>
          <Media query="(max-width: 599px)">{(matches) => <Sidebar isSmallScreen={matches} />}</Media>

          <Layout>
            <Header />
            <Layout.Content style={{ margin: '20px' }}>
              <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                <SwitchRoutes routes={getAllowedRoutes()} />
              </div>
            </Layout.Content>
          </Layout>
        </Layout>
      )}
    </div>
  );
};
