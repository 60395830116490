import { ComponentType, FC } from 'react';

import { Spinner } from './Spinner';

type LoadingProps = {
  loading: boolean;
};

export const withLoading =
  <P extends object>(Component: ComponentType<P>): FC<P & LoadingProps> =>
  ({ loading, ...props }: LoadingProps) => {
    if (loading) {
      return <Spinner />;
    }

    return <Component {...(props as P)} />;
  };

export const elementWithLoading = (element: JSX.Element, loading: boolean): JSX.Element => {
  if (loading) {
    return <Spinner />;
  }

  return element;
};
